import { useEffect, useState } from "react";
import { auth } from "../firebase";
import Authenticate from "../pages/Authenticate";

interface Props {
  children: JSX.Element;
}

const NeedAuthentication = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.currentUser !== null
  );

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsAuthenticated(user?.email !== undefined);
    });
  }, [setIsAuthenticated]);

  return isAuthenticated ? (
    children
  ) : (
    <>
      <p id="auth-message">Connecte-toi pour accèder à la page demandée</p>
      <Authenticate onAuthenticate={() => setIsAuthenticated(true)} />
    </>
  );
};

export default NeedAuthentication;
