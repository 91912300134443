import { useState } from "react";
import Input from "../../components/Input";
import { MainLogo } from "../Home";
import "./leave_print.scss";
import { Decoration, Loading, PrimaryButton } from "../../components";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../firebase";

const maxCharacters = 150;

const LeavePrint = () => {
  const [word, setWord] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function submit() {
    const res = await getDocs(
      query(
        collection(db, "WaitingList/words/list"),
        where("author", "==", auth.currentUser?.uid)
      )
    );
    if (res.docs.length > 0) {
      setError("Hmm, on dirait que tu as déjà laissé une marque... 🤔");
      return;
    }
    await addDoc(collection(db, "WaitingList/words/list"), {
      author: auth.currentUser?.uid,
      content: word,
      public: false,
    });
    setSuccess(true);
  }

  return (
    <>
      <MainLogo />
      <Decoration
        fillColor="primary"
        top={20}
        right={10}
        blur={200}
        opacity={0.1}
        parallax={-0.4}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(16)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <section className="part-page" id="leave-print-section">
        <div id="main-word">
          <h2>
            <span className="highlighted">Soutiens </span>le projet,
          </h2>
          <h2>
            Laisse <span className="highlighted">ta trace !</span>
          </h2>
        </div>
        <p id="paragraph">
          <em>Soutiens le projet</em> en laissant <em>ton empreinte</em> sur la
          page d'accueil du site web avec un <em>mot visible par tous !</em>
        </p>
        {success ? (
          <>
            <h2 id="success">
              Merci pour <span className="highlighted">ton soutien !!</span>
            </h2>
            <p id="success-subtitle">
              Ton empreinte sera laissée une fois ton message approuvé !
            </p>
            <PrimaryButton icon="⬅️" link="/">
              Retour à l'accueil
            </PrimaryButton>
          </>
        ) : (
          <>
            <div id="input" data-count={[word.length, maxCharacters].join("/")}>
              <Input
                type="textarea"
                label="Exprime-toi !"
                value={word}
                valid={word.length > maxCharacters ? false : undefined}
                onChange={(val) => setWord(val)}
              />
            </div>
            {error ? (
              <div id="error">
                <p id="error-msg">{error}</p>
                <p>
                  (Si le problème persiste, merci de nous contacter via{" "}
                  <a href="mailto:contact@swun.fr">contact@swun.fr</a>)
                </p>
              </div>
            ) : (
              <></>
            )}
            {isLoading ? (
              <Loading />
            ) : (
              <PrimaryButton
                enabled={word.length > 6 && word.length <= maxCharacters}
                icon="📢"
                onClick={async () => {
                  setIsLoading(true);
                  await submit();
                  setIsLoading(false);
                }}
              >
                Publier
              </PrimaryButton>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default LeavePrint;
