interface Props {
  path?: string;
}

const ProfilePicture = ({ path }: Props) => {
  return (
    <div
      className="profile-pic"
      style={{
        backgroundImage: `url(${path})`,
      }}
    />
  );
};

export default ProfilePicture;
