import { ReactNode, useEffect } from "react";

interface Props {
  children: ReactNode;
  selector?: string;
  offset?: number;
  setAnimate: (val: boolean) => void;
}

const AnimateOnScroll = ({ children, setAnimate, selector, offset }: Props) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setAnimate(entries[0].isIntersecting);
      },
      {
        threshold: offset ?? 0.1,
        rootMargin: "0px",
      }
    );

    // @ts-ignore: cannot cast
    observer.observe(document.querySelector(selector));
  }, [selector, setAnimate, offset]);

  return <>{children}</>;
};

export default AnimateOnScroll;
