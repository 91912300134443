import { useEffect, useState } from "react";
import {
  AlreadyMember,
  AnimateOnScroll,
  Decoration,
  Paragraph,
  PrimaryButton,
} from "../../components";
import "./join_page.scss";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { MainLogo } from "../Home";

const JoinPage = () => {
  const [signedPeople, setSignedPeople] = useState<number>();
  const [counter, setCounter] = useState<number>(0);
  const [animateNumber, setAnimateNumber] = useState(false);

  useEffect(() => {
    if (!signedPeople || (!animateNumber && counter === 0)) return;

    if (counter < signedPeople) {
      let timing = Math.pow(counter / signedPeople, 10);

      setTimeout(() => setCounter(counter + 1), timing * 300);
    }
  }, [animateNumber, signedPeople, counter, setCounter]);

  useEffect(() => {
    onSnapshot(doc(db, "WaitingList/numbers"), (snap) => {
      setSignedPeople(snap.data()?.users);
    });
  }, [setSignedPeople]);

  return (
    <>
      <MainLogo />
      <AlreadyMember />
      <section className="part-page" id="join-section">
        <Decoration
          fillColor="primary"
          top={20}
          left={5}
          blur={200}
          opacity={0.1}
          parallax={-0.6}
        >
          <svg
            viewBox="0 0 400 1200"
            width={400}
            height={1200}
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(-20)"
          >
            <rect width={400} height={1200} x1={0} y1={0} />
          </svg>
        </Decoration>
        <Decoration
          fillColor="primary"
          top={0}
          right={15}
          blur={200}
          opacity={0.1}
          parallax={0.2}
        >
          <svg
            viewBox="0 0 300 900"
            width={300}
            height={900}
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(14)"
          >
            <rect width={300} height={900} x1={0} y1={0} />
          </svg>
        </Decoration>
        <div id="main-word">
          <h2>
            Rejoins la <span className="highlighted">waiting list,</span>
          </h2>
          <h2>
            Rejoins <span className="highlighted">le projet SWUN</span>
          </h2>
        </div>
        <Paragraph highlighted="SWUN" id="swun-goal">
          est un nom qui a pour <em>vocation</em> de devenir{" "}
          <em>un emblème.</em>
          <br />
          <em className="animate-embleme" id="embleme-1">
            Un emblème <span className="embleme">sportif,</span>
          </em>
          <br />
          <em className="animate-embleme" id="embleme-2">
            Un emblème <span className="embleme">qui rassemble,</span>
          </em>
          <br />
          <em className="animate-embleme" id="embleme-3">
            Un emblème <span className="embleme">qui ouvre des portes,</span>
          </em>
          <br />
          <em className="animate-embleme" id="embleme-4">
            Un emblème <span className="embleme">qui aide !</span>
          </em>
        </Paragraph>
        <p className="paragraph" id="advantages">
          Bénéficie <em>d'avantages exclusifs</em> en rejoignant la waiting list
          :
        </p>
        <ul id="advantages-list">
          <li>
            <span className="emoji">🙋</span>
            <span className="sentence-start">
              Participe au développement du projet
            </span>{" "}
            en suggérant des sports vers lequels se tourner.
          </li>
          <li>
            <span className="emoji">📝</span>
            <span className="sentence-start">
              Laisse ta marque de soutien
            </span>{" "}
            sur la page principale du site web.
          </li>
          <li>
            <span className="emoji">📬</span>
            <span className="sentence-start">
              Reçois des mises à jour exclusives
            </span>{" "}
            sur l'avancée du projet.
          </li>
          <li>
            <span className="emoji">🎁</span>Obtient une chance de{" "}
            <span className="sentence-start">
              bénéficier d'avantages exclusifs
            </span>{" "}
            lors d'événements.
          </li>
        </ul>
        <AnimateOnScroll
          setAnimate={setAnimateNumber}
          offset={1}
          selector="#already-signed-counter"
        >
          <div id="already-signed-counter">
            <h3>
              <span className="underlined">
                <em>{counter}</em> personnes
              </span>{" "}
              font déjà partie du projet et ont créé leur compte, alors pourquoi
              pas toi ?
            </h3>
          </div>
        </AnimateOnScroll>
        <PrimaryButton icon="💪" link="/account">
          Rejoindre
        </PrimaryButton>
      </section>
    </>
  );
};

export default JoinPage;
