import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.scss";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import { JoinPage } from "./pages/Join";
import AccountPage from "./pages/Account";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LeavePrint from "./pages/LeavePrint";
import NeedAuthentication from "./components/NeedAuthentication";
import SportVoting from "./pages/SportVoting";
import GoWithCalshare from "./pages/GoWithCalshare";
import RegisterOrganisation from "./pages/Authenticate/RegisterOrganisation";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/join",
    element: <JoinPage />,
  },
  {
    path: "/account",
    element: (
      <NeedAuthentication>
        <AccountPage />
      </NeedAuthentication>
    ),
  },
  {
    path: "/join_as_organisation",
    element: <RegisterOrganisation />,
  },
  {
    path: "/leave-a-print",
    element: (
      <NeedAuthentication>
        <LeavePrint />
      </NeedAuthentication>
    ),
  },
  {
    path: "/vote",
    element: <SportVoting />,
  },
  {
    path: "/go_with_calshare",
    element: <GoWithCalshare />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} replace />,
  },
]);

const Links = () => {
  return (
    <div id="links">
      <a
        id="cgu"
        className="link"
        target="_blank"
        href="../assets/CGU_Calshare.pdf"
      >
        Conditions Générales d'Utilisation
      </a>
      <a
        id="privacy"
        className="link"
        target="_blank"
        href="calshare/privacy_policy/"
      >
        Politique de confidentialité
      </a>
      <a
        id="reimbursement"
        className="link"
        target="_blank"
        href="../assets/PR_Calshare.pdf"
      >
        Politique de remboursement
      </a>
      <a
        id="service-fees"
        className="link"
        target="_blank"
        href="../assets/Frais_Service_Calshare.pdf"
      >
        Frais de service
      </a>
      <a id="mail" className="link" href="mailto:contact@swun.fr">
        Nous contacter : contact@swun.fr
      </a>
    </div>
  );
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <div className="page">
        <RouterProvider router={router} />
        <div className="footer">
          <p>©️ 2024, SWUN</p>
          <Links />
        </div>
      </div>
    </QueryClientProvider>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
