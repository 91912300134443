import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  children: ReactNode;
  icon?: string;
  link?: string;
  onClick?: () => void;
  enabled?: boolean;
}

const PrimaryButton = ({ children, icon, link, onClick, enabled }: Props) => {
  return link ? (
    <Link to={link}>
      <button className="primary" data-icon={icon} disabled={enabled === false}>
        {children}
      </button>
    </Link>
  ) : (
    <button
      className="primary"
      data-icon={icon}
      onClick={onClick}
      disabled={enabled === false}
      title={
        enabled === false
          ? "Ce bouton n'aime pas les champs vides ou rouges 🙁"
          : ""
      }
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
