import { Link } from "react-router-dom";
import Decoration from "./Decoration";

const AlreadyMember = () => {
  return (
    <Decoration id="already-member" renderOnMobile>
      <p>Déjà membre ?</p>
      <Link to={"/account"} className="link">
        Mon compte ➡️
      </Link>
    </Decoration>
  );
};

export default AlreadyMember;
