import { useEffect, useRef, useState } from "react";
import { MainLogo } from "../Home";
import "./sport_voting.scss";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useUser, ViewOnlyUser } from "../../hooks";
import Input from "../../components/Input";
import { Decoration, Paragraph, PrimaryButton } from "../../components";

interface SportType {
  name: string;
  emoji?: string;
  submittedBy: string;
  reactions: {
    love: string[];
    no: string[];
    wow: string[];
  };
}

async function updateSportReactions(
  id: string,
  reactions: {
    love: string[];
    no: string[];
    wow: string[];
  }
) {
  console.log(reactions);
  await updateDoc(doc(db, `WaitingList/sports/list/${id}`), {
    reactions,
  });
}

function removeFromList(value: string, list: string[]) {
  if (list.includes(value)) {
    list.splice(list.indexOf(value), 1);
  }
}

const Sport = ({ submittedBy, name, emoji, reactions }: SportType) => {
  const { isLoading, data } = useUser<ViewOnlyUser>(submittedBy);
  const [userReaction, setUserReaction] = useState<string>();

  useEffect(() => {
    setUserReaction(
      reactions.love.includes(auth.currentUser?.uid ?? "")
        ? "love"
        : reactions.wow.includes(auth.currentUser?.uid ?? "")
        ? "wow"
        : reactions.no.includes(auth.currentUser?.uid ?? "")
        ? "no"
        : undefined
    );
  }, [reactions]);

  function react(reaction: "love" | "no" | "wow") {
    if (auth.currentUser === null) {
      return;
    }

    let newLoveReactions = [...reactions.love];
    let newWowReactions = [...reactions.wow];
    let newNoReactions = [...reactions.no];

    if (reaction === "love") {
      if (reactions.love.includes(auth.currentUser?.uid)) {
        removeFromList(auth.currentUser?.uid, newLoveReactions);
      } else {
        newLoveReactions.push(auth.currentUser?.uid);
        removeFromList(auth.currentUser?.uid, newWowReactions);
        removeFromList(auth.currentUser?.uid, newNoReactions);
      }
    }

    if (reaction === "wow") {
      if (reactions.wow.includes(auth.currentUser?.uid)) {
        removeFromList(auth.currentUser?.uid, newWowReactions);
      } else {
        newWowReactions.push(auth.currentUser?.uid);
        removeFromList(auth.currentUser?.uid, newLoveReactions);
        removeFromList(auth.currentUser?.uid, newNoReactions);
      }
    }

    if (reaction === "no") {
      if (reactions.no.includes(auth.currentUser?.uid)) {
        removeFromList(auth.currentUser?.uid, newNoReactions);
      } else {
        newNoReactions.push(auth.currentUser?.uid);
        removeFromList(auth.currentUser?.uid, newWowReactions);
        removeFromList(auth.currentUser?.uid, newLoveReactions);
      }
    }

    updateSportReactions(name, {
      love: newLoveReactions,
      wow: newWowReactions,
      no: newNoReactions,
    });
  }

  return isLoading ? (
    <></>
  ) : (
    <div className={`sport-card ${userReaction ? `user-${userReaction}` : ""}`}>
      {userReaction ? (
        <h3 id="user-reaction">
          {userReaction === "love"
            ? "❤️"
            : userReaction === "wow"
            ? "🤩"
            : "🙅"}
        </h3>
      ) : (
        <></>
      )}
      <div id="description">
        <h2 id="emoji">{emoji}</h2>
        <div>
          <h3 id="name">{name}</h3>
          <p className="submitter">Suggéré par : {data?.username}</p>
        </div>
      </div>
      <div className="sport-reactions">
        <div
          className={`reaction ${
            auth.currentUser === null ? "forbidden" : ""
          } ${userReaction === "love" ? "selected" : ""}`}
          id="love"
          onClick={() => react("love")}
        >
          <p id="emoji">❤️</p>
          <p>{reactions.love.length}</p>
        </div>
        <div
          className={`reaction ${
            auth.currentUser === null ? "forbidden" : ""
          } ${userReaction === "wow" ? "selected" : ""}`}
          id="wow"
          onClick={() => react("wow")}
        >
          <p id="emoji">🤩</p>
          <p>{reactions.wow.length}</p>
        </div>
        <div
          className={`reaction ${
            auth.currentUser === null ? "forbidden" : ""
          } ${userReaction === "no" ? "selected" : ""}`}
          id="no"
          onClick={() => react("no")}
        >
          <p id="emoji">🙅</p>
          <p>{reactions.no.length}</p>
        </div>
      </div>
    </div>
  );
};

const SportsPage = () => {
  const [sports, setSports] = useState<SportType[]>([]);

  useEffect(() => {
    onSnapshot(collection(db, "WaitingList/sports/list"), async (snap) => {
      const sports = snap.docs.filter((doc) => doc.data().public === true);

      setSports(
        sports.map((doc) => {
          let data = doc.data() as SportType;

          return {
            ...data,
            name: doc.id,
          };
        })
      );
    });
  }, []);

  return (
    <ul id="sports-list">
      {sports.map((sport) => (
        <li key={sport.name}>
          <Sport
            submittedBy={sport.submittedBy}
            name={sport.name}
            reactions={sport.reactions}
            emoji={sport.emoji}
          />
        </li>
      ))}
    </ul>
  );
};

const AddSportPage = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const [sport, setSport] = useState("");
  const [emoji, setEmoji] = useState("");
  const [duplicate, setDuplicate] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setCanSubmit(false);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const res = await getDocs(
        query(collection(db, "WaitingList/sports/list"))
      );

      for (let doc of res.docs) {
        if (doc.id === sport) {
          setDuplicate(true);
          return;
        }
      }

      setDuplicate(false);
      setCanSubmit(sport !== "");
    }, 1000);
  }, [sport]);

  async function submit() {
    if (!isLoggedIn) return;

    await setDoc(
      doc(db, "WaitingList", "sports", "list", sport.toLowerCase()),
      {
        submittedBy: auth.currentUser?.uid,
        emoji,
        reactions: {
          love: [],
          wow: [],
          no: [],
        },
        public: false,
      }
    );

    setSuccess(true);
  }

  return success ? (
    <>
      <h2 id="success">
        Merci pour <span className="highlighted">ton soutien !!</span>
      </h2>
      <p id="success-subtitle">
        Ton sport sera ouvert au vote une fois approuvé !<br />
        En attendant, tu peux jeter un oeil à ceux qui ont déjà été suggérés
      </p>
    </>
  ) : (
    <>
      <p>Suggère un sport à ajouter au projet SWUN !</p>
      <Input
        type="text"
        placeholder="Un sport qui te tient à coeur"
        value={sport}
        onChange={(val) => setSport(val)}
      />
      <Input
        type="text"
        placeholder="Un emoji pour l'illustrer ?"
        value={emoji}
        onChange={(val) => {
          if (emoji.length < 1 || val.length <= emoji.length) {
            setEmoji(val);
          }
        }}
      />
      {duplicate ? (
        <p id="duplicate">Hmm, ce sport a déjà été suggéré 🙁</p>
      ) : (
        <></>
      )}
      <PrimaryButton
        icon="💭"
        enabled={canSubmit && isLoggedIn}
        onClick={submit}
      >
        Suggérer
      </PrimaryButton>
    </>
  );
};

const SportVoting = () => {
  const [page, setPage] = useState(0);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsLoggedIn(user?.email !== undefined);
    });
  }, [setIsLoggedIn]);

  return (
    <>
      <MainLogo />
      <Decoration
        id="decoration-wow"
        top={15}
        right={20}
        parallax={-0.4}
        opacity={0.2}
      >
        <p>🤩</p>
      </Decoration>
      <Decoration
        id="decoration-love"
        bottom={-2}
        left={13}
        parallax={0.3}
        opacity={0.2}
      >
        <p>❤️</p>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={-30}
        right={10}
        blur={250}
        opacity={0.1}
        parallax={0.1}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(15)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <section className="part-page" id="sports">
        <div id="main-word">
          <h2>
            <span className="highlighted">Vote</span> pour un sport,
          </h2>
          <h2>
            Participe à <span className="highlighted">son intégration !</span>
          </h2>
        </div>
        <Paragraph highlighted="Pour faire les choses du mieux possible,">
          le projet SWUN fait <em>ses débuts</em> dans le{" "}
          <em>Street Workout (a.k.a. Callisthénie)</em> avant de s'étendre à
          d'autres sports.
        </Paragraph>
        <p id="sub-paragraph">
          Sur cette page, tu pourras <em>suggérer ou voter pour un sport.</em>
          <br />
          Celui qui suscitera <em>le plus d'intérêt positif</em> sera inclus en{" "}
          <em>premier</em> dans le projet.
        </p>
        <h3 id="encouragement">Bon vote à tous !</h3>
        <div id="page-switcher">
          <p onClick={() => setPage(0)}>Voter parmi la liste</p>
          <p onClick={() => setPage(1)}>Ajouter un sport</p>
          <div id="thumb" className={`page-${page}`}></div>
        </div>
      </section>
      <section className="full-page" id="sports-content">
        <div id="content-wrapper">
          <div id="sports-page" className={page === 0 ? "active" : "inactive"}>
            <SportsPage />
          </div>
          <div
            id="add-sport-page"
            className={page === 1 ? "active" : "inactive"}
          >
            <AddSportPage isLoggedIn={isLoggedIn} />
          </div>
        </div>
        {!isLoggedIn ? (
          <p id="auth-message">Connecte-toi pour voter ou suggérer un sport</p>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default SportVoting;
