import { MainLogo } from "../Home";
import "./go_with_calshare.scss";

const PostDemo = () => {
  return (
    <div id="post">
      <div id="header">
        <div id="profile-pic"></div>
        <div id="username"></div>
      </div>
      <img
        src="../assets/go_with_calshare_post.png"
        alt="Visuel pour aller à un événement avec calshare en format publication instagram carré"
      />
      <div id="footer">
        <svg
          id="like"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <path
            d="M250,426.39l-72.21-63.18c0,0-179.83-136.17-104.07-250.84C134.67,20.12,250,121.51,250,121.51
          s115.33-101.4,176.28-9.14c75.76,114.67-104.07,250.84-104.07,250.84L250,426.39z"
          />
        </svg>
        <svg
          id="comment"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <path
            d="M400.32,342.33l21.24,77.34l-81.19-18.19c-26.43,15.81-57.32,24.91-90.35,24.91
          c-97.43,0-176.42-78.99-176.42-176.36c0-97.43,78.99-176.42,176.42-176.42c97.43,0,176.36,78.99,176.36,176.42
          C426.39,283.85,416.87,315.47,400.32,342.33z"
          />
        </svg>
        <svg
          id="share"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <polygon points="52.79,73.61 447.21,73.61 185.18,219.16 	" />
          <polygon points="447.21,73.61 241.1,426.39 185.18,219.16 	" />
        </svg>
        <svg
          id="save"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <polygon points="89.17,426.39 89.17,73.61 410.83,73.61 410.83,426.39 250,281.13 " />
        </svg>
      </div>
    </div>
  );
};

const StoryDemo = () => {
  return (
    <div id="story">
      <div id="header">
        <div id="progress-bar"></div>
        <div id="profile">
          <div id="profile-pic"></div>
          <div id="username"></div>
        </div>
      </div>
      <img
        src="../assets/go_with_calshare_story.png"
        alt="Visuel pour aller à un événement avec calshare en format story instagram portrait 9:16"
      />
      <div id="footer">
        <svg
          id="share"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <polygon points="52.79,73.61 447.21,73.61 185.18,219.16 	" />
          <polygon points="447.21,73.61 241.1,426.39 185.18,219.16 	" />
        </svg>
      </div>
    </div>
  );
};

const GoWithCalshare = () => {
  return (
    <>
      <MainLogo />
      <section className="part-page">
        <div id="main-word">
          <h2>
            Ne les laissez <span className="highlighted">pas galérer,</span>
          </h2>
          <h2>
            Indiquez <span className="highlighted">le chemin</span> à{" "}
            <span className="highlighted">vos athlètes !</span>
          </h2>
        </div>
      </section>
      <section id="visuals" className="full-page">
        <div className="visual-wrapper">
          <h3>Format post</h3>
          <p className="format-indicator">(1:1 carré)</p>
          <PostDemo />
          <a
            href="../assets/go_with_calshare_post.png"
            data-icon="💾"
            download="go_with_calshare_post"
            className="dl-button"
          >
            Télécharger le visuel
          </a>
        </div>
        <div className="visual-wrapper">
          <h3>Format story</h3>
          <p className="format-indicator">(9:16 portrait)</p>
          <StoryDemo />
          <a
            href="../assets/go_with_calshare_story.png"
            data-icon="💾"
            download="go_with_calshare_story"
            className="dl-button"
          >
            Télécharger le visuel
          </a>
        </div>
      </section>
    </>
  );
};

export default GoWithCalshare;
