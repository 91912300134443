import { HTMLInputTypeAttribute, ReactNode, useState } from "react";
import "./input.scss";

interface Props {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  label?: string;
  type?: HTMLInputTypeAttribute | "textarea";
  helper?: ReactNode;
  helperShown?: boolean;
  disabled?: boolean;
  valid?: boolean;
  showPwd?: boolean;
  setShowPwd?: (checked: boolean) => void;
}

const ClosedEye = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 8C7.5 14.5 16.5 14.5 19.5 8" />
      <path d="M16.8162 11.3175L19.5 15" />
      <path d="M12 12.875V16.5" />
      <path d="M7.18383 11.3175L4.5 15" />
    </svg>
  );
};

const OpenedEye = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 12.5C7.5 6 16.5 6 19.5 12.5" />
      <path d="M12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14C14 15.1046 13.1046 16 12 16Z" />
    </svg>
  );
};

const Input = ({
  value,
  onChange,
  placeholder,
  label,
  type,
  helper,
  helperShown,
  disabled,
  valid,
  showPwd: overrideShowPwd,
  setShowPwd: overrideSetShowPwd,
}: Props) => {
  const [showPwd, setShowPwd] = useState(false);

  return (
    <div
      className={`input ${
        valid !== undefined ? "input-" + (!!valid ? "valid" : "invalid") : ""
      }`}
    >
      {label ? <p id="input-label">{label}</p> : <></>}
      <div className="input-container">
        <div id="input-wrapper">
          {type === "textarea" ? (
            <textarea
              value={value}
              onChange={(e) => onChange(e.currentTarget.value)}
              placeholder={placeholder}
              disabled={disabled}
            />
          ) : (
            <input
              value={value}
              onChange={(e) => onChange(e.currentTarget.value)}
              placeholder={placeholder}
              type={
                type === "password"
                  ? overrideShowPwd || showPwd
                    ? "text"
                    : "password"
                  : type
              }
              disabled={disabled}
            />
          )}
          {type === "password" ? (
            <div
              className="pwd-eye"
              onClick={() => {
                if (overrideSetShowPwd) {
                  overrideSetShowPwd(!overrideShowPwd);
                  return;
                }

                setShowPwd((prev) => !prev);
              }}
            >
              {overrideShowPwd || showPwd ? <OpenedEye /> : <ClosedEye />}
            </div>
          ) : (
            <></>
          )}
        </div>
        {helper ? (
          <div className={`helper ${helperShown ? "shown" : ""}`}>{helper}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Input;
