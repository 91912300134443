import "./words_list.scss";
import { db, functions } from "../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import Loading from "./Loading";

interface Word {
  content: string;
  author: string;
  id: string;
  vip?: boolean;
}

const WordsList = () => {
  const [words, setWords] = useState<Word[]>([]);

  const addWord = useCallback(
    (word: Word) => {
      setWords((prev) => {
        const newWords = [...prev];

        if (!newWords.find((el) => el.author === word.author)) {
          newWords.push(word);
        }

        return newWords;
      });
    },
    [setWords]
  );

  useEffect(() => {
    onSnapshot(
      collection(db, "WaitingList", "words", "list"),
      async (wordsCollection) => {
        const docs = wordsCollection.docs.filter(
          (doc) => doc.data()?.public !== false
        );
        docs.forEach((doc) =>
          httpsCallable(
            functions,
            "users-getUserInfoForView"
          )({
            uuids: [doc.data().author],
          }).then((res) => {
            addWord({
              content: doc.data()?.content,
              author:
                (res.data as { username: string }[])[0]?.username ?? "Inconnu",
              id: doc.data()?.author,
              vip: doc.data()?.vip,
            });
          })
        );
      }
    );
  }, [addWord]);

  return words.length === 0 ? (
    <Loading />
  ) : (
    <div id="words-list-container">
      <ul
        className="words-list"
        style={{
          animationDuration: `${4 * words.length}s`,
        }}
      >
        {words.map((word) => (
          <li
            className={`user-word ${word.vip === true ? "vip" : ""}`}
            key={`${word.author}-${word.content}`}
          >
            <p>{word.content}</p>
            <h3>{word.author}</h3>
          </li>
        ))}
      </ul>
      <ul
        className="words-list"
        id="second-list"
        style={{
          animationDuration: `${4 * words.length}s`,
        }}
      >
        {words.map((word) => (
          <li
            className={`user-word ${word.vip === true ? "vip" : ""}`}
            key={`${word.author}-${word.content}`}
          >
            <p>{word.content}</p>
            <h3>{word.author}</h3>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default WordsList;
