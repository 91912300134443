// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD54sn-M286T3BbLI70S5XuR75KDr5DTlg",
  authDomain: "swun-calshare.firebaseapp.com",
  databaseURL:
    "https://swun-calshare-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "swun-calshare",
  storageBucket: "swun-calshare.appspot.com",
  messagingSenderId: "138569991736",
  appId: "1:138569991736:web:a38e7fbb604ff08ec748cd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions();

export { db, auth, functions };
export default app;
