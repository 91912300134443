import { Link } from "react-router-dom";
import "./enroll_button.scss";

interface Props {
  message?: string;
}

const EnrollButton = ({ message }: Props) => {
  return (
    <Link to={"/join"}>
      <button className="secondary">
        {message ?? "Rejoindre maintenant"}
        {!message ? <span id="rocket">🚀</span> : <></>}
      </button>
    </Link>
  );
};

export default EnrollButton;
