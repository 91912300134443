import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  decoration: ReactNode;
  className?: string;
  id?: string;
}

const DecoratedProjectSection = ({
  children,
  decoration,
  className,
  id,
}: Props) => {
  return (
    <section className={`${className} decorated-section`} id={id}>
      <div className="section-content">{children}</div>
      <div className="section-decoration">{decoration}</div>
    </section>
  );
};

export default DecoratedProjectSection;
